<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <circle cx="12.0001"
            cy="7.70781"
            r="3.89799"
            stroke="currentColor"
            stroke-width="1.8"
        />
        <path
            d="M19.0122 21.0902C19.0122 17.3087 15.8727 14.2432 12 14.2432C8.12726 14.2432 4.98779 17.3087 4.98779 21.0902"
            stroke="currentColor"
            stroke-width="1.8"
            stroke-linecap="round"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
